import React from 'react'
import { BrandsProps } from './types'
import { Container } from '@/components/base/gridview'
import Image from '@/components/base/image'
import { useApp } from '@wap-client/core'

const Brands: React.FC<BrandsProps> = ({ brands }) => {
  const app = useApp()
  return (
    <section className="brands">
      <Container size="extended">
        <div
          className="title"
          dangerouslySetInnerHTML={{
            __html: app.settings.translations['ourinvestment'],
          }}
        />
        <div className="brands-parent">
          {brands?.map((item, index) => (
            <Image className="logo" key={index} {...item.brandlogo} alt="" />
          ))}
        </div>
      </Container>
    </section>
  )
}

export default Brands
